<template>
  <slot :is-active="isActive" :activate="activate" :deactivate="deactivate" />
</template>

<script setup lang="ts">
import { ref } from 'vue';

const isActive = ref<boolean>(false);

const activate = (): void => {
  isActive.value = true;
};

const deactivate = (): void => {
  isActive.value = false;
};
</script>
